import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import headerImg from "../assets/img/wicecitygirls.jpg";
import founderImg from "../assets/img/founder.png";
import twitterImg from "../assets/img/wicetwitter.PNG";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner2 = () => {

  return (
    <section className="banner2" id="home">
      <Container>
      <img src={founderImg} alt="Header Img" className="founder"/>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" className="banner2_images"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div>
              
                  <img src={twitterImg} alt="Header Img" className="banner2_images"/>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
