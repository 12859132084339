import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from '../assets/img/logo.svg';
import headerImg from "../assets/img/PS4.png";
import twitterImg from "../assets/img/apeszn.png";
import caImg from "../assets/img/whitefont.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
        <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" className="game"/>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              
              <div>       
                  <img src={twitterImg} alt="Header Img" className="padded"/>
                  <p><br></br>CA: WicEiFpGAaCi83RP15u97119hf6AuJ8J4FK49Z665uP</p>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
