import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/wicecity.png";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/telegramsvg.svg';
import navIcon3 from '../assets/img/dexscreener.svg';
import navIcon4 from '../assets/img/solscan.svg';
export const Footer = () => {
  return (
    
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            
          <div className="social-icon">
                <a href="https://twitter.com/WiceCitySOL"><img src={navIcon1} alt="" /></a>
                <a href="https://t.me/WiceCitySOL"><img src={navIcon2} alt="" /></a>
                <a href="https://dexscreener.com/"><img src={navIcon3} alt="" /></a>
                <a href="https://solscan.io/token/WicEiFpGAaCi83RP15u97119hf6AuJ8J4FK49Z665uP"><img src={navIcon4} alt="" /></a>
              </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
